<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-clock-outline</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Stundenplan</v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <p>
      <v-tooltip>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="openImportDialog"
            color="info"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-upload</v-icon> Stundenplan aus Untis importieren
          </v-btn>
        </template>
        Stundenplan aus Untis importieren.
      </v-tooltip>
    </p>
    <p>
      <v-btn href="https://intern.gymkirchenfeld.ch/timetable" target="_blank"
        ><v-icon>mdi-open-in-new</v-icon> Stundenpläne auf intern
      </v-btn>
    </p>
    <p>
      <v-switch
        inset
        hide-details
        v-model="nextTermPublished"
        :label="label"
      ></v-switch>
    </p>
    <router-view></router-view>
  </v-container>
</template>
<script>
export default {
  name: 'Timetable',
  components: {},
  data() {
    return {
      nextTermPublished: false,
    };
  },
  computed: {
    label() {
      return this.nextTermPublished
        ? 'nächstes Semester publiziert'
        : 'nächstes Semester noch nicht publiziert';
    },
  },
  watch: {
    async nextTermPublished() {
      await this.apiPost({
        resource: 'course/timetable',
        data: { nextTermPublished: this.nextTermPublished },
      });
    },
  },
  async created() {
    const result = await this.apiList({ resource: 'course/timetable' });
    this.nextTermPublished = result.nextTermPublished;
  },
  methods: {
    openImportDialog() {
      this.$router.push({
        name: 'TimetableImport',
      });
    },
  },
};
</script>
